const InactivedItem = () => {
  return (
    <>
      <div className={`w-[120px] h-[120px]`}>
        <img src="/home/bgIcon/inactive.svg" className="w-full h-full" />
      </div>
    </>
  );
};

export default InactivedItem;
